<template>
  <v-autocomplete
    :items="albums"
    :label="i18n(label)"
    :loading="loading"
    :outlined="outlined"
    :readonly="readonly"
    :clearable="clearable"
    :autofocus="autofocus"
    :filter="() => true"
    :search-input.sync="search"
    :hide-details="hideDetails"
    :no-data-text="'filter.sync'.translate()"
    v-model="album"
    @keyup.enter="$emit('on-enter', $event)"
  />
</template>

<script>
import { requests } from '@/plugins/Amplify'
const onlyNumber = (str) => isNaN(str) ? null : Number(str)
const onlyString = (str) => String(str || '').trim()

export default {
  props: {
    value: {},
    label: { default: 'field.album' },
    outlined: { default: false },
    readonly: { default: false },
    autofocus: { default: false },
    clearable: { default: false },
    hideDetails: { default: true }
  },

  data () {
    return {
      albums: [],
      search: null,
      loading: false,
      timeout: null,
      album: onlyNumber(this.value)
    }
  },

  methods: {
    clearTimeout () {
      clearTimeout(this.timeout)
      this.timeout = null
    },

    loadAlbums (params) {
      this.loading = true

      this.timeout = setTimeout(async () => {
        try {
          const data = await requests.getAlbums(params)
          this.albums = data.map(({ id, nome }) => ({ value: id, text: nome }))
        } catch (error) {
          console.error(error)
        }

        this.loading = false
      }, 275)
    }
  },

  mounted () {
    if (this.value) this.loadAlbums({ id: this.value })
  },

  watch: {
    album () {
      const album = onlyNumber(this.album)
      const changed = album !== onlyNumber(this.value)
      if (changed) this.$emit('input', album)
    },

    value () {
      const value = onlyNumber(this.value)
      if (value === onlyNumber(this.album)) return

      this.album = value
      this.loadAlbums({ id: value })
    },

    search () {
      this.clearTimeout()

      const nome = onlyString(this.search)
      if (!nome || this.albums.find(({ text }) => text === nome)) {
        this.loading = false
        return
      }

      this.loadAlbums({ nome })
    }
  }
}
</script>
