<template>
  <v-autocomplete
    :items="tipos"
    :label="i18n(label)"
    :outlined="outlined"
    :readonly="readonly"
    :clearable="clearable"
    :autofocus="autofocus"
    :prepend-inner-icon="icon"
    :hide-details="hideDetails"
    v-model="tipo"
  />
</template>

<script>
const onlyNumber = (str) => isNaN(str) ? null : Number(str)
export default {
  props: {
    icon: {},
    value: {},
    label: { default: 'field.tipo' },
    outlined: { default: false },
    readonly: { default: false },
    clearable: { default: false },
    autofocus: {},
    hideDetails: { default: true }
  },

  data () {
    return {
      tipo: onlyNumber(this.value),
      tipos: [
        { value: 1, text: 'Oratórias' },
        { value: 2, text: 'Histórias' },
        { value: 3, text: 'Chamadas' },
        { value: 4, text: 'Sessão' },
        { value: 5, text: 'Músicas' },
        { value: 6, text: 'Explicação' },
        { value: 7, text: 'Palavra do Mestre' }
      ]
    }
  },

  watch: {
    tipo () {
      const tipo = onlyNumber(this.tipo)
      const changed = tipo !== onlyNumber(this.value)
      if (changed) this.$emit('input', tipo)
    },

    value () {
      const value = onlyNumber(this.value)
      const changed = value !== onlyNumber(this.tipo)
      if (changed) this.tipo = value
    }
  }
}
</script>
