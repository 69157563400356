<template>
  <v-autocomplete
    :items="qualidades"
    :label="i18n(label)"
    :outlined="outlined"
    :readonly="readonly"
    :clearable="clearable"
    :autofocus="autofocus"
    :prepend-inner-icon="icon"
    :hide-details="hideDetails"
    v-model="qualidade"
  />
</template>

<script>

export default {
  props: {
    icon: {},
    value: {},
    label: { default: 'field.qualidade' },
    outlined: { default: false },
    readonly: { default: false },
    clearable: { default: false },
    autofocus: {},
    hideDetails: { default: true }
  },

  data () {
    return {
      qualidade: String(this.value || '').trim(),
      qualidades: [
        { value: 'B', text: 'Bom' },
        { value: 'R', text: 'Ruim' }
      ]
    }
  },

  watch: {
    qualidade (qualidade) {
      const newValue = String(qualidade || '').trim()
      const oldValue = String(this.value || '').trim()
      if (oldValue === newValue) return
      this.$emit('input', newValue)
    },

    value (qualidade) {
      const oldValue = String(this.qualidade || '').trim()
      const newValue = String(qualidade || '').trim()
      if (oldValue === newValue) return
      this.qualidade = newValue
    }
  }
}
</script>
