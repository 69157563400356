<template>
  <v-autocomplete
    :items="sessoes"
    :label="i18n(label)"
    :outlined="outlined"
    :readonly="readonly"
    :clearable="clearable"
    :autofocus="autofocus"
    :prepend-inner-icon="icon"
    :hide-details="hideDetails"
    v-model="sessao"
  />
</template>

<script>
const onlyNumber = (str) => isNaN(str) ? null : Number(str)

export default {
  props: {
    icon: {},
    value: {},
    label: { default: 'field.sessao' },
    outlined: { default: false },
    readonly: { default: false },
    clearable: { default: false },
    autofocus: {},
    hideDetails: { default: true }
  },

  data () {
    return {
      sessao: onlyNumber(this.value),
      sessoes: [
        { value: 35, text: 'Quadro de Mestres' },
        { value: 33, text: 'Sessão da Direção' },
        { value: 32, text: 'Sessão Instrutiva' },
        { value: 34, text: 'Caráter Instrutivo' },
        { value: 20, text: 'Sessão da 1ª Escala' },
        { value: 21, text: 'Sessão da 2ª Escala' },
        { value: 22, text: 'Escala Anual Natal' },
        { value: 23, text: 'Escala Anual de Reis' },
        { value: 24, text: 'Escala Anual Aniversário do Mestre' },
        { value: 25, text: 'Escala Anual Ressurreição do Mestre' },
        { value: 26, text: 'Escala Anual São João' },
        { value: 27, text: 'Escala Anual Aniversário da União' },
        { value: 28, text: 'Escala Anual Confirmação da União no Astral supeior' },
        { value: 30, text: 'Sessão extra Ano Novo' },
        { value: 31, text: 'Sessão extra de São Cosmo e São Damião' },
        { value: 29, text: 'Sessão extra' },
        { value: 36, text: 'Sessão de Casal' },
        { value: 37, text: 'Sessão de Jovens' },
        { value: 38, text: 'Distribuição de Vegetal em Preparo' },
        { value: 39, text: 'Sessão de Adventício' }
      ]
    }
  },

  watch: {
    sessao () {
      const sessao = onlyNumber(this.sessao)
      const changed = sessao !== onlyNumber(this.value)
      if (changed) this.$emit('input', sessao)
    },

    value () {
      const value = onlyNumber(this.value)
      const changed = value !== onlyNumber(this.sessao)
      if (changed) this.sessao = value
    }
  }
}
</script>
