<template>
  <v-autocomplete
    :items="permissoes"
    :label="i18n(label)"
    :outlined="outlined"
    :readonly="readonly"
    :clearable="clearable"
    :autofocus="autofocus"
    :prepend-inner-icon="icon"
    :hide-details="hideDetails"
    v-model="permissao"
  />
</template>

<script>

const permissoes = [
  { value: 'QM', text: 'QM' },
  { value: 'CONACE', text: 'CONACE' },
  { value: 'CONAGE', text: 'CONAGE' }
]

export default {
  props: {
    icon: {},
    value: {},
    label: { default: 'field.permissao' },
    outlined: { default: false },
    readonly: { default: false },
    clearable: { default: false },
    autofocus: {},
    hideDetails: { default: true }
  },

  data () {
    const permissao = String(this.value || '').trim()

    return {
      permissao,
      permissoes
    }
  },

  watch: {
    permissao (permissao) {
      const newValue = String(permissao || '').trim()
      const oldValue = String(this.value || '').trim()
      if (oldValue === newValue) return
      this.$emit('input', newValue)
    },

    value (permissao) {
      const oldValue = String(this.permissao || '').trim()
      const newValue = String(permissao || '').trim()
      if (oldValue === newValue) return
      this.permissao = newValue
    }
  }
}
</script>
