<template>
  <v-autocomplete
    :items="oradores"
    :label="i18n(label)"
    :loading="loading"
    :outlined="outlined"
    :readonly="readonly"
    :clearable="clearable"
    :autofocus="autofocus"
    :filter="() => true"
    :search-input.sync="search"
    :hide-details="hideDetails"
    :no-data-text="'filter.sync'.translate()"
    v-model="orador"
    @keyup.enter="$emit('on-enter', $event)"
  />
</template>

<script>
import { requests } from '@/plugins/Amplify'
const onlyNumber = (str) => isNaN(str) ? null : Number(str)
const onlyString = (str) => String(str || '').trim()

export default {
  props: {
    value: {},
    label: { default: 'field.orador' },
    outlined: { default: false },
    readonly: { default: false },
    autofocus: { default: false },
    clearable: { default: false },
    hideDetails: { default: true }
  },

  data () {
    return {
      oradores: [],
      search: null,
      loading: false,
      timeout: null,
      orador: onlyNumber(this.value)
    }
  },

  methods: {
    clearTimeout () {
      clearTimeout(this.timeout)
      this.timeout = null
    },

    loadOradores (params) {
      this.loading = true

      this.timeout = setTimeout(async () => {
        try {
          const data = await requests.getOradores(params)
          this.oradores = data.map(({ id, nome }) => ({ value: id, text: nome }))
        } catch (error) {
          console.error(error)
        }

        this.loading = false
      }, 275)
    }
  },

  mounted () {
    if (this.value) this.loadOradores({ id: this.value })
  },

  watch: {
    orador () {
      const orador = onlyNumber(this.orador)
      const changed = orador !== onlyNumber(this.value)
      if (changed) this.$emit('input', orador)
    },

    value () {
      const value = onlyNumber(this.value)
      if (value === onlyNumber(this.orador)) return

      this.orador = value
      this.loadOradores({ id: value })
    },

    search () {
      this.clearTimeout()

      const nome = onlyString(this.search)
      if (!nome || this.oradores.find(({ text }) => text === nome)) {
        this.loading = false
        return
      }

      this.loadOradores({ nome })
    }
  }
}
</script>
