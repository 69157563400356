<template>
  <v-combobox
    multiple
    small-chips
    hide-selected
    :outlined="outlined"
    :readonly="readonly"
    :hide-details="hideDetails"
    :search-input.sync="keyWord"
    label="Palavras Chaves"
    v-model="keyWords"
  >
    <template #no-data>
      <v-list-item>
        <span
          v-if="keyWord"
          class="subheading"
        >Adicionar</span>
        &nbsp;&nbsp;&nbsp;
        <v-chip
          v-if="keyWord"
          dark
          color="green darken-4"
          label
          x-small
        >
          {{ keyWord }}
        </v-chip>
      </v-list-item>
    </template>

    <template #selection="{ attrs, item, parent, selected }">
      <v-chip
        v-bind="attrs"
        color="green lighten-4"
        :input-value="selected"
        label
        small
      >
        <span class="pr-1">{{ item }}</span>
        <v-icon
          v-if="readonly === false"
          x-small
          @click="parent.selectItem(item)"
        >
          mdi-close
        </v-icon>
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>

const formatWords = (str) => String(str || '').trim().split(',').sort().map((v) => String(v || '').trim()).filter(v => v)

export default {
  props: {
    value: {},
    label: { default: 'field.keywords' },
    outlined: { default: false },
    readonly: { default: false },
    autofocus: {},
    hideDetails: { default: true }
  },

  data () {
    return {
      keyWord: null,
      keyWords: formatWords(this.value)
    }
  },

  watch: {
    keyWords (keyWords) {
      const empty = Array.isArray(keyWords) ? !keyWords.filter(v => String(v || '').trim()).length : true
      const value = empty ? null : keyWords.map(v => String(v || '').trim()).filter(v => v).join(',')
      if (value !== this.value) this.$emit('input', value)
    },

    value (value) {
      const words = String(value || '').trim().split(',').filter(v => String(v || '').trim())
      this.keyWords = words.length ? words : null
    }
  }
}
</script>
